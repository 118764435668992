import { Table, StatusIndicator, Spinner, Link } from "@cloudscape-design/components";
import { useNavigate } from "react-router-dom";
import { AssignmentObject, CourseObject } from "../api";
import { formatDate } from "../util/FormatUtil";

export const AssignmentList = (props: { assignments: AssignmentObject[], courseMap?: Map<string, CourseObject>, hideCourse?: boolean }) => {
    const navigate = useNavigate();

    const { assignments } = props;
    return (
        <Table
            items={assignments}
            empty={<div>No Assignments</div>}
            onRowClick={(row) => navigate(`/courses/${row.detail.item.courseId}/assignments/${row.detail.item.id}`)}
            columnDefinitions={
                [{
                    id: 'name',
                    header: 'Assignment Name',
                    cell: (item: AssignmentObject) => <span style={{ cursor: 'pointer' }}>{item?.assignmentName}</span>
                },
                {
                    id: 'dueDate',
                    header: 'Due Date',
                    cell: (item: AssignmentObject) => formatDate(new Date(item?.dueDate))
                },
                props.hideCourse ? undefined : {
                    id: 'course',
                    header: 'Course',
                    cell: (item: AssignmentObject) => props.courseMap?.get(item.courseId)?.courseCode
                },
                {
                    id: 'status',
                    header: 'Status',
                    cell: (item: AssignmentObject) => {
                        let statusType;
                        if (new Date(item?.dueDate).getTime() < new Date().getTime()) {
                            statusType = 'success';
                        } else {
                            statusType = 'in-progress';
                        }
                        return <StatusIndicator type={statusType as any}>{statusType == 'success' ? 'Results ready' : 'Waiting for submissions'}</StatusIndicator>
                    }
                }
                ].filter(e => !!e) as any
            }
        />
    );
}