import { ContentLayout, Link, SpaceBetween, Header, Button, Container, ColumnLayout, Spinner, BreadcrumbGroup, Box, FormField, Input, Wizard } from "@cloudscape-design/components";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FictitiousAPI, { LmsOAuthConfigObject } from "../api";
import { AnalyticsService } from "../services/AnalyticsService";

export const ConfigureClassrooms = () => {
    const navigate = useNavigate();

    const [configData, setConfigData] = useState<LmsOAuthConfigObject | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    const [endpoint, setEndpoint] = useState<string>("https://canvas.instructure.com");
    const [clientId, setClientId] = useState<string>("");
    const [clientSecret, setClientSecret] = useState<string>("");

    const [
        activeStepIndex,
        setActiveStepIndex
    ] = useState(0);

    useEffect(() => {
        loadConfig();
    }, []);

    const loadConfig = async () => {
        setLoading(true);
        const config = (await FictitiousAPI.domain.getConfigurationUsingGet()).data;
        setConfigData(config);
        setLoading(false);
    }

    return <ContentLayout
        header={
            <SpaceBetween size="m">
                <BreadcrumbGroup
                    onClick={(e) => { e.preventDefault(); navigate(e.detail.href) }}
                    items={[
                        { text: "Home", href: "/" },
                        { text: "Integrations", href: "/integrations" },
                        { text: "Google Classroom", href: "/classrooms" },
                    ]}
                    ariaLabel="Breadcrumbs"
                />
                <div></div>
                {configData && <Header
                    variant="h1"
                    description="You can configure your Google Classroom integration from here."

                >
                    Configure Google Classroom
                </Header>}
            </SpaceBetween>
        }
    >
        <Container><Header>Coming soon!</Header><p>The Google Classroom integration will support automatic imports of courses, assignments, and submissions, and is set to be released on June 15th.</p></Container>
    </ContentLayout >
}