import { Badge, BreadcrumbGroup, BreadcrumbGroupProps, SideNavigation, SideNavigationProps, TopNavigation } from "@cloudscape-design/components";
import { useLocation, useNavigate } from 'react-router-dom';

const HOME = { text: "FictitiousAI", href: "/" };
const items: SideNavigationProps.Item[] = [
    {
        type: "section",
        text: "Educator Demo",
        items: [
            { type: "link", text: "Tech Demo", href: "/tech-demo", info: <Badge color="blue">NEW!</Badge> },
        ]
    },
    {
        type: "section",
        text: "Educator Console",
        items: [
            { type: "link", text: "Home", href: "/" },
            { type: "link", text: "Courses", href: "/courses" },
            { type: "link", text: "Assignments", href: "/assignments" },
        ]
    },
    {
        type: "section",
        text: "Settings",
        items: [
            { type: "link", text: "All Integrations", href: "/integrations" },
            { type: "link", text: "Canvas LMS Integration", href: "/integrations/canvas" },
            { type: "link", text: "Google Classroom Integration", href: "/integrations/classroom" },
        ]
    }
]


const demoItems: SideNavigationProps.Item[] = [
    {
        type: "section",
        text: "Educator Demo",
        items: [
            { type: "link", text: "Tech Demo", href: "/tech-demo" },
        ]
    },
    {
        type: "section",
        text: "Educator Console",
        items: [
            { type: "link", text: "Home", href: "/" },
            { type: "link", text: "Courses", href: "/courses" },
            { type: "link", text: "Assignments", href: "/assignments" },
        ]
    }
]

export function Breadcrumbs({ items }: { items: BreadcrumbGroupProps.Item[] }) {
    return <BreadcrumbGroup items={[HOME, ...items]} />;
}

export function ServiceNavigation(props: {
    isDemo?: boolean;
}) {
    const location = useLocation();
    const navigate = useNavigate();

    const onFollowHandler = (event: CustomEvent<SideNavigationProps.FollowDetail>) => {
        event.preventDefault();
        if (event.detail.href) {
            navigate(event.detail.href);
        }
    }
    return <><SideNavigation
        items={items}
        activeHref={`#${location.pathname}`}
        onFollow={onFollowHandler} />
    </>
}