/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ChangePasswordRequest,
  LoginRequest,
  LoginResponse,
  ProvisionDemoUserResponse,
  RegisterDemoUserRequest,
  UserAccount,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Accounts<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags account-server
   * @name ChangePasswordUsingPost
   * @summary changePassword
   * @request POST:/accounts/changePassword
   */
  changePasswordUsingPost = (
    request: ChangePasswordRequest,
    query?: {
      authenticated?: boolean;
      "authorities[0].authority"?: string;
      credentials?: object;
      details?: object;
      principal?: object;
    },
    params: RequestParams = {},
  ) =>
    this.request<UserAccount, void>({
      path: `/accounts/changePassword`,
      method: "POST",
      query: query,
      body: request,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-server
   * @name LoginUsingPost
   * @summary login
   * @request POST:/accounts/login
   */
  loginUsingPost = (request: LoginRequest, params: RequestParams = {}) =>
    this.request<LoginResponse, void>({
      path: `/accounts/login`,
      method: "POST",
      body: request,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-server
   * @name GetUserUsingGet
   * @summary getUser
   * @request GET:/accounts/me
   */
  getUserUsingGet = (
    query?: {
      authenticated?: boolean;
      "authorities[0].authority"?: string;
      credentials?: object;
      details?: object;
      principal?: object;
    },
    params: RequestParams = {},
  ) =>
    this.request<UserAccount, void>({
      path: `/accounts/me`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags account-server
   * @name RegisterDemoUserUsingPost
   * @summary registerDemoUser
   * @request POST:/accounts/registerDemoUser
   */
  registerDemoUserUsingPost = (request: RegisterDemoUserRequest, params: RequestParams = {}) =>
    this.request<ProvisionDemoUserResponse, void>({
      path: `/accounts/registerDemoUser`,
      method: "POST",
      body: request,
      type: ContentType.Json,
      ...params,
    });
}
