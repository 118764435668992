import { ContentLayout, SpaceBetween, Header, Button, Container, Table, StatusIndicator, Tabs, ColumnLayout, BreadcrumbGroup } from "@cloudscape-design/components";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FictitiousAPI, { AssignmentObject, CourseObject, GetCourseResponse } from "../api";
import { AssignmentList } from "../components/AssignmentList";
import { KeyValue } from "../components/KeyValue";
import { FictitiousApiWrapper } from "../services/FictitiousApiWrapper";
import { formatDate } from "../util/FormatUtil";

export const CourseDetails = () => {
    const navigate = useNavigate();
    const { courseId } = useParams();
    const [courseDetails, setCourseDetails] = useState<GetCourseResponse | null>(null);
    const [assignments, setAssignments] = useState<AssignmentObject[]>([]);

    useEffect(() => {
        loadCourse();
    }, [courseId]);

    const loadCourse = async () => {
        if (courseId) {
            const courses = await FictitiousAPI.domain.getCourseInfoUsingPost(courseId);
            setCourseDetails(courses.data);
            const assignments = await FictitiousAPI.domain.listAssignmentsForCourseUsingGet(courseId);
            setAssignments(assignments.data.assignmentObjects.sort((a, b) => a.dueDate.getTime() - b.dueDate.getTime()));
        }
    }

    const renderStudents = () => (
        <Table
            header={<Header>Students</Header>}
            items={courseDetails?.students || []}
            columnDefinitions={
                [{
                    id: 'name',
                    header: 'Student Name',
                    cell: (item) => <span style={{ cursor: 'pointer' }}>{item?.name || "-"}</span>
                },
                {
                    id: 'email',
                    header: 'Student Email',
                    cell: (item) => item?.email || "-"
                },
                ]
            }
        />
    );

    const renderAssignments = () => (

        <SpaceBetween size="m">
            <Button variant="primary" onClick={() => navigate(`/courses/${courseId}/assignments`)}>View Full List</Button>
            <AssignmentList assignments={assignments} hideCourse={true} />
        </SpaceBetween>
    );

    return <ContentLayout
        header={
            <SpaceBetween size="m">
                <BreadcrumbGroup
                    onClick={(e) => { e.preventDefault(); navigate(e.detail.href) }}
                    items={[
                        { text: "Home", href: "/" },
                        { text: "Courses", href: "/courses" },
                        {
                            text: ((courseDetails?.courseObject.courseCode || "") + " " + courseDetails?.courseObject.name) || "-",
                            href: "/courses/" + courseId
                        }
                    ]}
                    ariaLabel="Breadcrumbs"
                />
                <div></div>
                <Header
                    variant="h1"
                    description="You can view course details from this page."
                // actions={
                //     <Button variant="primary" onClick={() => navigate(`/courses/${courseId}/assignments`)}>View Assignments</Button>
                // }
                >
                    Details for {courseDetails?.courseObject.name}
                </Header>
            </SpaceBetween>
        }
    >
        <SpaceBetween size="l">
            <Container>
                <ColumnLayout columns={2} variant='text-grid'>
                    <SpaceBetween size="l">
                        <KeyValue label="Course Code">
                            {courseDetails?.courseObject.courseCode}
                        </KeyValue>
                        <KeyValue label="Course Name">
                            {courseDetails?.courseObject.name}
                        </KeyValue>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <KeyValue label="Course Description">
                            {courseDetails?.courseObject.description || "-"}
                        </KeyValue>
                        <KeyValue label="Total Enrollment">
                            {courseDetails?.students.length || "0"}
                        </KeyValue>
                    </SpaceBetween>
                </ColumnLayout>
            </Container>
            <Container>
                <Tabs tabs={[
                    { id: 'assignments', label: 'Assignments', content: renderAssignments() },
                    { id: 'students', label: 'Students', content: renderStudents() },
                ]} />
            </Container>
        </SpaceBetween>
    </ContentLayout>
}