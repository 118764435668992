import moment from "moment";

export function formatDate(input?: Date): string {
    if (!input) return '';
    return moment(input).format('dddd, MMM DD YYYY • hh:mm A');
}

export function formatPrice(input?: number): string {
    if (!input) return '';
    return `$${input.toFixed(2)}`
}