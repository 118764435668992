export class AnalyticsService {
    private static readonly ENDPOINT = 'https://hooks.slack.com/services/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';

    public static trackCanvasConfiguration(name: any, email: any) {
        return fetch(AnalyticsService.ENDPOINT, { method: 'POST', body: JSON.stringify({ text: `Canvas config created by ${name} (${email})` }) });
    }

    public static trackDemoSignup(name: string, email: string) {
        return fetch(AnalyticsService.ENDPOINT, { method: 'POST', body: JSON.stringify({ text: `Demo signup from ${name} (${email})` }) });
    }

    public static trackDemoInitialization(submissionId: string) {
        return fetch(AnalyticsService.ENDPOINT, { method: 'POST', body: JSON.stringify({ text: 'Demo initialized with ID' + submissionId }) });
    }

    public static sendDemoFeedback(submissionId: string, feedback: string) {
        return fetch(AnalyticsService.ENDPOINT, { method: 'POST', body: JSON.stringify({ text: `Received feedback from ${submissionId}: ${feedback}` }) });
    }
}