import { AppLayout, Header, Table, StatusIndicator, Button, Container, ContentLayout, SpaceBetween, Spinner, BreadcrumbGroup } from "@cloudscape-design/components"
import { useEffect, useState } from "react";
import { ServiceNavigation } from "../navigation"
import { useNavigate, useParams } from 'react-router-dom';
import { FictitiousApiWrapper } from "../services/FictitiousApiWrapper";
import { formatDate } from "../util/FormatUtil";
import FictitiousAPI, { AssignmentObject, CourseObject, ListAssignmentsResponse } from "../api";
import { HttpResponse } from "../api/lib/http-client";
import { AssignmentList } from "../components/AssignmentList";

export const AssignmentsHome = () => {
    const navigate = useNavigate();
    const { courseId } = useParams();
    const [course, setCourse] = useState<CourseObject | undefined>(undefined);
    const [assignments, setAssignments] = useState<any[]>([]);
    const [courseMap, setCourseMap] = useState<Map<string, CourseObject>>(new Map());

    useEffect(() => {
        loadAssignments();
        if (courseId !== undefined) {
            FictitiousAPI.domain.getCourseInfoUsingPost(courseId).then((response) => {
                setCourse(response.data.courseObject);
            });
        }
    }, [courseId]);

    const loadAssignments = async () => {
        let assignments: HttpResponse<ListAssignmentsResponse>;
        if (courseId !== undefined) {
            assignments = await FictitiousAPI.domain.listAssignmentsForCourseUsingGet(courseId);
        } else {
            assignments = await FictitiousAPI.domain.listAssignmentsUsingGet();
        }
        setAssignments(assignments.data.assignmentObjects.sort((a, b) => a.dueDate.getTime() - b.dueDate.getTime()));
        if (!courseId) {
            for (const assignment of assignments.data.assignmentObjects) {
                FictitiousAPI.domain.getCourseInfoUsingPost(assignment.courseId)
                    .then(course => {
                        setCourseMap(new Map(courseMap.set(course.data.courseObject.id, course.data.courseObject)))
                    });
            }
        }
    }

    return <ContentLayout
        header={
            <SpaceBetween size="m">
                <BreadcrumbGroup
                    onClick={(e) => { e.preventDefault(); navigate(e.detail.href) }}
                    items={[
                        { text: "Home", href: "/" },
                        courseId ? { text: "Courses", href: "/courses" } : { text: "Assignments", href: "/assignments" },
                        courseId && { text: ((course?.courseCode || "") + " " + course?.name) || "-", href: "/courses/" + (courseId) },
                        courseId && { text: "Assignments", href: `/courses/${course?.id}/assignments` },
                    ].filter(e => e !== undefined) as any}
                    ariaLabel="Breadcrumbs"
                />
                <div></div>
                <Header
                    variant="h1"
                    description="Click on an assignment to view details and submissions."
                    // actions={
                    //     <Button variant="primary">Sync from LMS</Button>
                    // }
                >
                    Assignments for {course?.courseCode || 'all courses'}
                </Header>
            </SpaceBetween>
        }
    >
        <Container>
            {!assignments && <Spinner />}
            <AssignmentList assignments={assignments} courseMap={courseMap} />
        </Container>
    </ContentLayout>
}