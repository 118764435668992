export default class AuthService {
    public static storedCredentialsAvailable(): boolean {
        return (window.localStorage.getItem("com.fictitious.authtoken")) != null;
    }

    public static clearStoredCredentials(): void {
        window.localStorage.removeItem("com.fictitious.authtoken");
    }

    public static storeToken(token: string): void {
        window.localStorage.setItem("com.fictitious.authtoken", token);
    }

    public static getStoredToken(): string {
        const token = window.localStorage.getItem("com.fictitious.authtoken");
        if (token == null) {
            throw new Error("No token available");
        }
        return token;
    }
}