import { Container, Header, Spinner } from "@cloudscape-design/components";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import FictitiousAPI from "../api";
import AuthService from "../services/AuthService";
// @ts-ignore
import mixpanel from 'mixpanel-browser';

export const DemoTokenCaptor = () => {
    const { token } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        FictitiousAPI.setSecurityData({ method: 'Local', token: token as any as string });
        AuthService.storeToken(token as any as string);
        mixpanel.track('Demo Initiated', { token: token as any as string });
        navigate("/tech-demo");
    }, [token]);

    return <Container>
        <Spinner />
        <Header>Starting demo...</Header>
    </Container>
}