import { useEffect } from "react";
import { redirect, useNavigate } from "react-router-dom";
import FictitiousAPI from "../api";
import AuthService from "../services/AuthService";

export const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        console.log("Clearing stored credentials.");
        AuthService.clearStoredCredentials();
        FictitiousAPI.setSecurityData({} as any);
        navigate("/")
    }, []);

    return <></>;
}