import { Container, StatusIndicator, SpaceBetween, Grid, Popover } from "@cloudscape-design/components";
import { SubmissionObject } from "../api";

export const EssayResults = (props: { submission: SubmissionObject }) => {
    const { submission } = props;
    return (
        <Container>
            {submission?.predictionState !== 'COMPLETE' && <StatusIndicator type='in-progress'>Essay analysis in progress</StatusIndicator>}
            <SpaceBetween size='l'>
                {
                    submission?.predictionResultObject?.paragraphResults?.map(paragraph => {
                        if (paragraph.text?.trim().length == 0 || paragraph.text?.trim() == '.') {
                            return <></>
                        }
                        let status;
                        let aggregateProb = paragraph.result?.aggregateFake || 0;
                        let finalProb = aggregateProb;
                        let finalConfidence = paragraph.result?.confidence!;
                        if ((paragraph.text?.split('.').length || 5) <= 3) {
                            finalConfidence = 0.25;
                        }
                        if (paragraph.result?.perplexity! < 10) {
                            finalConfidence = 1.0;
                            finalProb = Math.sqrt(Math.sqrt(Math.sqrt(aggregateProb)));
                        }
                        else if (paragraph.result?.perplexity! <= 20) {
                            finalConfidence = 0.75;
                            finalProb = Math.sqrt(Math.sqrt(aggregateProb));
                        }
                        if (paragraph.result?.perplexity! > 20) {
                            finalProb = aggregateProb / 3;
                            finalConfidence = 0.51;
                        }
                        else if (paragraph.result?.perplexity! > 30) {
                            finalProb = aggregateProb / 3;
                            finalConfidence = 1.0;
                        }
                        if (finalProb < 0.3 && finalProb > 0.2) {
                            finalProb /= 4;
                        }
                        let baseOpacity = 0.8 * finalConfidence;
                        if (finalProb > 0.3 && finalProb < 0.6 && finalConfidence < 0.5) {
                            baseOpacity = 0;
                        }
                        let color = `rgba(148, 255, 134, ${baseOpacity})`;
                        if (finalProb > 0.3) {
                            status = 'error';
                            color = `rgba(255, 224, 134, ${baseOpacity})`;
                        }
                        if (finalProb > 0.4) {
                            status = 'error';
                            color = `rgba(255, 176, 93, ${baseOpacity})`;
                        }
                        if (finalProb > 0.8) {
                            status = 'error';
                            color = `rgba(255, 135, 135, ${baseOpacity})`;
                        } else if (finalProb <= 0.3) {
                            status = 'success';
                        }
                        return <Grid
                            gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
                        >
                            <div style={{ backgroundColor: color, padding: 10, borderRadius: 10 }}>
                                {paragraph.sentenceResults?.map(result => {
                                    let sentenceProb = result?.result?.aggregateFake || 0;
                                    // if (sentenceProb > 0.75) {
                                    //     if ((result?.text?.split(' ').length || 9) < 8) {
                                    //         sentenceProb /= 3;
                                    //     }
                                    // }
                                    let color;
                                    let finalConfidence = result?.result?.confidence!;
                                    if (result?.result?.perplexity! < 15) {
                                        sentenceProb *= 1.5;
                                        finalConfidence = 0.51;
                                    }
                                    else if (result?.result?.perplexity! < 10) {
                                        sentenceProb *= 3;
                                        finalConfidence = 0.75;
                                    }
                                    if (result?.result?.perplexity! > 20) {
                                        finalConfidence = 0.51;
                                        sentenceProb /= 1.5;
                                    }
                                    else if (result?.result?.perplexity! > 30) {
                                        finalConfidence = 0.75;
                                        sentenceProb /= 3;
                                    }
                                    else if (result?.result?.perplexity! > 50) {
                                        finalConfidence = 1.0;
                                        sentenceProb /= 5;
                                    }
                                    let opacity = 0.8 * finalConfidence;
                                    if (finalProb > 0.3 && finalProb < 0.6 && finalConfidence < 0.5) {
                                        opacity = 0;
                                    }
                                    if (finalProb < 0.2) {
                                        // green
                                        color = `rgba(148, 255, 134, ${opacity})`;
                                    }
                                    else if (sentenceProb > 0.25) {
                                        // yellow
                                        color = `rgba(255, 224, 134, ${opacity})`;
                                    }
                                    if (sentenceProb > 0.6 || finalProb > 0.8 && (result?.text?.split(' ').length || 7) > 6) {
                                        // orange
                                        color = `rgba(255, 176, 93, ${opacity})`;
                                    }
                                    if (sentenceProb > 0.8 && finalProb > 0.6 && (result?.text?.split(' ').length || 7) > 6) {
                                        // red
                                        color = `rgba(255, 135, 135, ${opacity})`;
                                    }
                                    return <span style={{ backgroundColor: color, padding: 2, borderRadius: 5, margin: 2 }}>{result?.text?.replaceAll('.. ', '.').replaceAll('  ', ' ')}</span>
                                })}
                            </div>
                            <StatusIndicator type={status as any}>{(100 * (finalProb)).toFixed(2) + "% AI-generated"}</StatusIndicator>
                        </Grid>
                    })
                }
            </SpaceBetween>
        </Container>
    );
}