import { ContentLayout, Link, SpaceBetween, Header, Button, Container, ColumnLayout, Spinner, BreadcrumbGroup, Box, FormField, Input, Wizard } from "@cloudscape-design/components";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FictitiousAPI, { LmsOAuthConfigObject } from "../api";
import { AnalyticsService } from "../services/AnalyticsService";

export const ConfigureCanvas = () => {
    const navigate = useNavigate();

    const [configData, setConfigData] = useState<LmsOAuthConfigObject | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    const [endpoint, setEndpoint] = useState<string>("https://canvas.instructure.com");
    const [clientId, setClientId] = useState<string>("");
    const [clientSecret, setClientSecret] = useState<string>("");

    const [
        activeStepIndex,
        setActiveStepIndex
    ] = useState(0);

    useEffect(() => {
        loadConfig();
    }, []);

    const loadConfig = async () => {
        setLoading(true);
        const config = (await FictitiousAPI.domain.getConfigurationUsingGet()).data;
        setConfigData(config);
        setLoading(false);
    }

    return <ContentLayout
        header={
            <SpaceBetween size="m">
                <BreadcrumbGroup
                    onClick={(e) => { e.preventDefault(); navigate(e.detail.href) }}
                    items={[
                        { text: "Home", href: "/" },
                        { text: "Integrations", href: "/integrations" },
                        { text: "Canvas", href: "/canvas" },
                    ]}
                    ariaLabel="Breadcrumbs"
                />
                <div></div>
                {configData && <Header
                    variant="h1"
                    description="You can configure your Canvas LMS integration from here."

                >
                    Configure Canvas Integration
                </Header>}
            </SpaceBetween>
        }
    >
        {loading && <Spinner />}
        {configData && <Container><Header>You're all set!</Header><p>Your integration is already configured for {configData.endpoint}. Need to change this? Please <a href="mailto@support@fictitious.ai">contact us</a> for help.</p></Container>}
        {!configData &&
            <Wizard
                i18nStrings={{
                    stepNumberLabel: stepNumber =>
                        `Step ${stepNumber}`,
                    collapsedStepsLabel: (stepNumber, stepsCount) =>
                        `Step ${stepNumber} of ${stepsCount}`,
                    skipToButtonLabel: (step, stepNumber) =>
                        `Skip to ${step.title}`,
                    navigationAriaLabel: "Steps",
                    cancelButton: "Cancel",
                    previousButton: "Previous",
                    nextButton: "Next",
                    submitButton: "Create Integration",
                    optional: "optional"
                }}
                onNavigate={({ detail }) => {
                    if (detail.requestedStepIndex == 2) {
                        if (!endpoint || !clientId || !clientSecret) {
                            alert("Please fill in all fields before continuing.");
                            return;
                        }
                    }
                    setActiveStepIndex(detail.requestedStepIndex);
                }
                }
                onSubmit={() => {
                    if (!endpoint || !clientId || !clientSecret) {
                        alert("Please fill in all fields before submitting.");
                        return;
                    }
                    setLoading(true);
                    FictitiousAPI.domain.createConfigurationUsingPost({
                        canvasEndpoint: endpoint,
                        clientId: clientId,
                        clientSecret: clientSecret
                    }).then(() => {
                        FictitiousAPI.accounts.getUserUsingGet().then((user) => {
                            AnalyticsService.trackCanvasConfiguration(user.data.fullName, user.data.email);
                        });
                        setLoading(false);
                        navigate("/integrations");
                    });
                }}
                activeStepIndex={activeStepIndex}
                steps={[
                    {
                        title: "Configure Canvas Integration",
                        info: <Link>Info</Link>,
                        description: "Follow the steps below to get started. You'll need access to your institution's Canvas admin account to complete the setup process.",
                        content: (
                            <Container
                                header={
                                    <Header variant="h2">
                                        Create a developer key
                                    </Header>
                                }
                            >
                                <p>You need to create a developer key in Canvas to connect your instance to FictitiousAI.</p>
                                <p>The Canvas developer key is used so that FictitiousAI can read information about your courses and assignments.</p>
                                <p>A guide on creating a key can be found here: <a target={"_blank"} href="https://community.canvaslms.com/t5/Admin-Guide/How-do-I-add-a-developer-API-key-for-an-account/ta-p/259">create a developer API key</a></p>
                            </Container>
                        )
                    },
                    {
                        title: "Set developer key",
                        info: <Link target="_blank" href="https://community.canvaslms.com/t5/Admin-Guide/How-do-I-add-a-developer-API-key-for-an-account/ta-p/259">Info</Link>,
                        description:
                            "Now, copy the API key you just created into the fields below.",
                        content: (
                            <Container
                                header={
                                    <Header variant="h2">
                                    </Header>
                                }
                            >
                                <SpaceBetween direction="vertical" size="l">
                                    <FormField label="Canvas URL*">
                                        <Input value={endpoint} onChange={(text) => setEndpoint(text.detail.value)} />
                                    </FormField>
                                    <FormField label="Client ID*">
                                        <Input value={clientId} onChange={(text) => setClientId(text.detail.value)} />
                                    </FormField>
                                    <FormField label="Client Secret*">
                                        <Input value={clientSecret} onChange={(text) => setClientSecret(text.detail.value)} />
                                    </FormField>
                                </SpaceBetween>
                            </Container>
                        )
                    },
                    {
                        title: "Review and submit",
                        content: (
                            <SpaceBetween size="xs">
                                <Header
                                    variant="h3"
                                    actions={
                                        <Button
                                            onClick={() => setActiveStepIndex(0)}
                                        >
                                            Edit
                                        </Button>
                                    }
                                >
                                    Developer Key Details
                                </Header>
                                <Container
                                // header={
                                //     <Header variant="h2">
                                //         {/* Container title */}
                                //     </Header>
                                // }
                                >
                                    <ColumnLayout
                                        columns={3}
                                        variant="text-grid"
                                    >
                                        <div>
                                            <Box variant="awsui-key-label">
                                                Endpoint
                                            </Box>
                                            <div>{endpoint}</div>
                                        </div>
                                        <div>
                                            <Box variant="awsui-key-label">
                                                Client ID
                                            </Box>
                                            <div>{clientId}</div>
                                        </div>
                                        <div>
                                            <Box variant="awsui-key-label">
                                                Client Secret
                                            </Box>
                                            <div>{clientSecret}</div>
                                        </div>
                                    </ColumnLayout>
                                </Container>
                            </SpaceBetween>
                        )
                    }
                ]}
            />
        }
    </ContentLayout >
}