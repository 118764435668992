import Header from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import { useState } from "react";
import Alert from "@cloudscape-design/components/alert";
import { Grid } from "@cloudscape-design/components";
import FictitiousAPI from "../api";
import AuthService from "../services/AuthService";

export const Login = (props: {
    onAuth: () => void;
}) => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const [error, setError] = useState<string | null>(null);

    const login = async () => {
        setLoading(true);
        try {
            const response = await FictitiousAPI.accounts.loginUsingPost({ email, password });
            if (response.data.success) {
                FictitiousAPI.setSecurityData({ method: 'Local', token: response.data.token as any as string });
                AuthService.storeToken(response.data.token as any as string);
                setLoading(false);
                (window as any).intercomSettings = {
                    api_base: "https://api-iam.intercom.io",
                    app_id: "noapcxie",
                    name: response.data.userAccount?.fullName, // Full name
                    email: response.data.userAccount?.email, // Email address
                };
                props.onAuth();
            } else {
                setError("Incorrect username or password. Please contact us for help.");
            }
        } catch (e) {
            console.error(e);
            setError("An unrecognized error occurred.");
        }
        setLoading(false);
    }

    return <Container>
        {error &&
            <Alert
                dismissAriaLabel="Close alert"
                header="Login Error"
                type="error"
                dismissible={true}
                onDismiss={() => setError(null)}
            >{error}</Alert>
        }
        <Grid
            gridDefinition={[{ colspan: 3 }, { colspan: 6 }, { colspan: 3 }]}
        >
            <div />
            <div>
                <SpaceBetween size="m">
                    <Header variant="h1">FictitousAI Console Login</Header>
                    <Container>
                        <SpaceBetween size="s">
                            <span>Enter your username and password below.</span>
                            <Input
                                type='email'
                                placeholder="Email Address"
                                value={email}
                                onChange={(e) => setEmail(e.detail.value)}
                            />
                            <Input
                                type='password'
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.detail.value)}
                            />
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button variant="primary" loading={loading} onClick={login}>Login</Button>
                                <Button variant="link" href="/demo-user-signup">Need an account?</Button>
                            </SpaceBetween>
                        </SpaceBetween>
                    </Container>
                </SpaceBetween>
            </div>
            <div />
        </Grid>
    </Container>
}