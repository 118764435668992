import { ContentLayout, SpaceBetween, Header, Button, Alert, Container, Link, Cards, StatusIndicator } from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import { useNavigate, useNavigation } from "react-router-dom";
import FictitiousAPI, { AssignmentObject, CourseObject, SubmissionObject } from "../api";
import { formatDate } from "../util/FormatUtil";
import { AssignmentDetails } from "./AssignmentDetails";

export const Home = () => {
    const navigate = useNavigate();

    const [assignments, setAssignments] = useState<AssignmentObject[]>([]);

    const [courses, setCourses] = useState<Map<string, CourseObject>>(new Map());

    const [submissions, setSubmissions] = useState<Map<string, SubmissionObject[]>>(new Map());

    useEffect(() => {
        const fetchData = async () => {
            const response = await FictitiousAPI.domain.listAssignmentsUsingGet();
            const soonestAssignments = response.data.assignmentObjects.sort((a, b) => b.dueDate.getDate() - a.dueDate.getDate());
            setAssignments(soonestAssignments);
            for (const a of soonestAssignments) {
                FictitiousAPI.domain.getCourseInfoUsingPost(a.courseId).then(course => {
                    setCourses(new Map(courses.set(a.courseId, course.data.courseObject)));
                });
                if (a.dueDate < new Date()) {
                    FictitiousAPI.domain.listSubmissionsUsingGet(a.id).then(submission => {
                        setSubmissions(new Map(submissions.set(a.id, submission.data.submissions)));
                    });
                }
            }
        }
        fetchData();
    }, []);

    return <> <ContentLayout
        header={
            <SpaceBetween size="m">
                <Header
                    variant="h1"
                    description="Welcome to the FictitiousAI admin console. You can configure and view essay analyses from here."
                >
                    FictitiousAI Admin Console
                </Header>
            </SpaceBetween>
        }
    >
        <SpaceBetween size="m">
            <Container
                header={
                    <Header
                        variant="h2"
                        description="View the status of your assignments with submission analysis results."
                        actions={
                            <Button variant="primary" onClick={() => navigate("/assignments")}>View All</Button>
                        }
                    >
                        Past Due Assignments
                    </Header>
                }
            >
                <Cards items={assignments.filter(a => a.dueDate < new Date()).sort((a, b) => a.dueDate.getTime() - b.dueDate.getTime()).slice(0, 3)}
                    empty={<Alert type="info">No recently-due assignments found.</Alert>}
                    cardDefinition={{
                        header: e => {
                            let assignmentState = 'pending';
                            let message = 'Awaiting Submissions';
                            if (e.dueDate < new Date()) {
                                assignmentState = 'in-progress';
                                // if all submissions are done processing, mark as complete
                                if (submissions.get(e.id)?.every(s => s.predictionState === 'COMPLETE')) {
                                    assignmentState = 'success';
                                    message = 'Results Available';
                                } else if (submissions.get(e.id)?.some(s => s.predictionState === 'FAILURE')) {
                                    assignmentState = 'failed';
                                    message = 'Analysis Failed';
                                }
                            }
                            return <StatusIndicator type={assignmentState as any}>{message}</StatusIndicator>
                        },
                        sections: [
                            { id: 'assignment', header: "Name", content: e => e.assignmentName },
                            { id: 'course', header: "Course", content: e => courses.get(e.courseId)?.name },
                            { id: 'dueDate', header: "Due Date", content: e => formatDate(e.dueDate) },
                            { id: 'details', content: e => <Link onFollow={() => navigate(`/courses/${e.courseId}/assignments/${e.id}`)}>View Details</Link> },
                        ]
                    }}
                    cardsPerRow={[{ cards: 3 }]}
                />
            </Container>
            <Container
                header={
                    <Header
                        variant="h2"
                        description="View the status of your upcoming assignments."
                        actions={
                            <Button variant="primary" onClick={() => navigate("/assignments")}>View All</Button>
                        }
                    >
                        Upcoming Assignments
                    </Header>
                }
            >
                <Cards items={assignments.filter(a => a.dueDate > new Date()).sort((a, b) => a.dueDate.getTime() - b.dueDate.getTime()).slice(0, 3)}
                    empty={<Alert type="info">No upcoming assignments found.</Alert>}
                    cardDefinition={{
                        header: e => {
                            let assignmentState = 'pending';
                            let message = 'Awaiting Submissions';
                            if (e.dueDate < new Date()) {
                                assignmentState = 'in-progress';
                                // if all submissions are done processing, mark as complete
                                if (submissions.get(e.id)?.every(s => s.predictionState === 'COMPLETE')) {
                                    assignmentState = 'complete';
                                    message = 'Results Available';
                                } else if (submissions.get(e.id)?.some(s => s.predictionState === 'FAILURE')) {
                                    assignmentState = 'failed';
                                    message = 'Analysis Failed';
                                }
                            }
                            return <StatusIndicator type={e.dueDate < new Date() ? 'pending' : 'in-progress'}>{message}</StatusIndicator>
                        },
                        sections: [
                            { id: 'assignment', header: "Name", content: e => e.assignmentName },
                            { id: 'course', header: "Course", content: e => courses.get(e.courseId)?.name },
                            { id: 'dueDate', header: "Due Date", content: e => formatDate(e.dueDate) },
                            { id: 'details', content: e => <Link onFollow={() => navigate(`/courses/${e.courseId}/assignments/${e.id}`)}>View Details</Link> },
                        ]
                    }}
                    cardsPerRow={[{ cards: 3 }]}
                />
            </Container>
        </SpaceBetween>
    </ContentLayout></>;
}