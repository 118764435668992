import { BreadcrumbGroup, Button, ColumnLayout, Container, ContentLayout, Header, SpaceBetween, Spinner, Tabs } from '@cloudscape-design/components';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FictitiousAPI, { AssignmentObject, CourseObject, GetCourseResponse, StudentObject, SubmissionObject } from '../api';
import { AssignmentList } from '../components/AssignmentList';
import { KeyValue } from '../components/KeyValue';
import { SubmissionList } from '../components/SubmissionList';
import { formatDate } from '../util/FormatUtil';

export const AssignmentDetails = () => {
    const { assignmentId, courseId } = useParams();
    const navigate = useNavigate();

    const [assignment, setAssignment] = useState<AssignmentObject | undefined>(undefined);
    const [course, setCourse] = useState<GetCourseResponse | undefined>(undefined);
    const [submissions, setSubmissions] = useState<SubmissionObject[]>([]);
    const [students, setStudents] = useState<Map<string, StudentObject>>(new Map());

    const loadAssignment = async () => {
        const result = await FictitiousAPI.domain.getAssignmentUsingGet(assignmentId as string);
        setAssignment(result.data.assignmentObject);
        if (result?.data?.assignmentObject?.courseId) {
            const course = await FictitiousAPI.domain.getCourseInfoUsingPost(result.data.assignmentObject.courseId);
            setCourse(course.data);
            const students = new Map();
            for (const student of course.data.students) {
                students.set(student.id, student);
            }
            setStudents(students);
        }
    }

    const loadSubmissions = async () => {
        const results = await FictitiousAPI.domain.listSubmissionsUsingGet(assignmentId as string);
        setSubmissions(results.data.submissions);
    }

    useEffect(() => {
        loadAssignment();
        if (courseId) {
            FictitiousAPI.domain.getCourseInfoUsingPost(courseId).then(response => setCourse(response.data));
        }
        loadSubmissions();
    }, [assignmentId]);

    const renderSubmissions = () => {
        return (
            <SpaceBetween size="m">
                <Button variant="primary" onClick={() => navigate(`/courses/${courseId}/assignments/${assignmentId}/submissions`)}>View Full List</Button>
                <SubmissionList submissions={submissions} course={course} assignmentId={assignmentId} students={students} />
            </SpaceBetween>
        );
    }

    return (<ContentLayout
        header={
            <SpaceBetween size="m">
                <BreadcrumbGroup
                    onClick={(e) => { e.preventDefault(); navigate(e.detail.href) }}
                    items={[
                        { text: "Home", href: "/" },
                        courseId ? { text: "Courses", href: "/courses" } : { text: "Assignments", href: "/assignments" },
                        courseId && { text: ((course?.courseObject.courseCode || "") + " " + course?.courseObject.name) || "-", href: "/courses/" + (courseId) },
                        courseId && { text: "Assignments", href: `/courses/${course?.courseObject.id}/assignments` },
                        { text: assignment?.assignmentName, href: `/courses/${course?.courseObject.id}/assignments/${assignmentId}` },
                    ].filter(e => e !== undefined) as any}
                    ariaLabel="Breadcrumbs"
                />
                <div></div>
                <Header
                    variant="h1"
                    description="Click on an assignment to view details and submissions."
                    // actions={
                    //     <Button variant="primary">Sync from LMS</Button>
                    // }
                >
                    Details for {assignment?.assignmentName}
                </Header>
            </SpaceBetween>
        }
    >
        <SpaceBetween size="l">
            <Container>
                <ColumnLayout columns={3} variant='text-grid'>
                    <SpaceBetween size="l">
                        <KeyValue label="Assignment Name">
                            {assignment?.assignmentName}
                        </KeyValue>
                        <KeyValue label="Due Date">
                            {formatDate(assignment?.dueDate)}
                        </KeyValue>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <KeyValue label="Course Code">
                            {course?.courseObject.courseCode}
                        </KeyValue>
                        <KeyValue label="Course Name">
                            {course?.courseObject.name}
                        </KeyValue>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <KeyValue label="Total Submissions">
                            {submissions.length}
                        </KeyValue>
                        <KeyValue label="Total Course Enrollment">
                            {course?.students.length || "0"}
                        </KeyValue>
                    </SpaceBetween>
                </ColumnLayout>
            </Container>
            <Container>
                <Tabs tabs={[
                    { id: 'submissions', label: 'Submissions', content: renderSubmissions() },
                    // { id: 'students', label: 'Students', content: renderStudents() },
                ]} />
            </Container>
        </SpaceBetween>
    </ContentLayout>)
}