import { ContentLayout, SpaceBetween, Header, Button, Container, Table, StatusIndicator, ColumnLayout, BreadcrumbGroup } from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router"
import FictitiousAPI, { AssignmentObject, CourseObject, GetCourseResponse, StudentObject, SubmissionObject } from "../api";
import { KeyValue } from "../components/KeyValue";
import { SubmissionList } from "../components/SubmissionList";
import { FictitiousApiWrapper } from "../services/FictitiousApiWrapper";
import { formatDate } from "../util/FormatUtil";

export const SubmissionsHome = () => {
    const { assignmentId, courseId } = useParams();

    const navigate = useNavigate();

    const [assignment, setAssignment] = useState<AssignmentObject | undefined>(undefined);
    const [submissions, setSubmissions] = useState<SubmissionObject[]>([]);
    const [course, setCourse] = useState<GetCourseResponse | undefined>(undefined);
    const [students, setStudents] = useState<Map<string, StudentObject>>(new Map());

    useEffect(() => {
        loadAssignment();
        loadSubmissions();
    }, [assignmentId]);

    const loadAssignment = async () => {
        const result = await FictitiousAPI.domain.getAssignmentUsingGet(assignmentId as string);
        setAssignment(result.data.assignmentObject);
        if (result?.data?.assignmentObject?.courseId) {
            const course = await FictitiousAPI.domain.getCourseInfoUsingPost(result.data.assignmentObject.courseId);
            setCourse(course.data);
            const students = new Map();
            for (const student of course.data.students) {
                students.set(student.id, student);
            }
            setStudents(students);
        }
    }

    const loadSubmissions = async () => {
        const results = await FictitiousAPI.domain.listSubmissionsUsingGet(assignmentId as string);
        setSubmissions(results.data.submissions);
    }

    return <ContentLayout
        header={
            <SpaceBetween size="m">
                <BreadcrumbGroup
                    onClick={(e) => { e.preventDefault(); navigate(e.detail.href) }}
                    items={[
                        { text: "Home", href: "/" },
                        { text: "Courses", href: `/courses/${courseId}/assignments` },
                        {
                            text: ((course?.courseObject.courseCode || "") + " " + course?.courseObject.name) || "-",
                            href: "/courses/" + courseId
                        },
                        { text: "Assignments", href: `/courses/${courseId}/assignments` },
                        { text: assignment?.assignmentName, href: `/courses/${courseId}/assignments/${assignmentId}` },
                        { text: "Submissions", href: `/courses/${courseId}/assignments/${assignmentId}/submissions` },
                    ].filter(e => e !== undefined) as any}
                    ariaLabel="Breadcrumbs"
                />
                <div></div>
                <Header
                    variant="h1"
                    description="Click on a row to view the full report for a student's submission."
                    actions={
                        <Button variant="primary" disabled>Download Report (coming soon)</Button>
                    }
                >
                    Submissions for {assignment?.assignmentName}
                </Header>
            </SpaceBetween>
        }
    >
        <SpaceBetween size="l">
            <Container>
                <ColumnLayout columns={3} variant='text-grid'>
                    <SpaceBetween size="l">
                        <KeyValue label="Assignment Name">
                            {assignment?.assignmentName}
                        </KeyValue>
                        <KeyValue label="Due Date">
                            {formatDate(assignment?.dueDate)}
                        </KeyValue>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <KeyValue label="Course Code">
                            {course?.courseObject.courseCode}
                        </KeyValue>
                        <KeyValue label="Total Enrollment">
                            {course?.students.length}
                        </KeyValue>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <KeyValue label="Number of Submissions">
                            {submissions?.length}
                        </KeyValue>
                        <KeyValue label="Flagged Submissions">
                            {submissions?.filter(submission => submission?.predictionResultObject?.result?.fake || 0 > 0.8).length}
                        </KeyValue>
                    </SpaceBetween>
                </ColumnLayout>
            </Container>
            <Container>
                <SubmissionList submissions={submissions} course={course} assignmentId={assignmentId} students={students} />
            </Container>
        </SpaceBetween>
    </ContentLayout>
}