import { Alert, AppLayout, Button, Checkbox, Container, ContentLayout, FormField, Header, Input, SpaceBetween, Spinner, TopNavigation } from "@cloudscape-design/components"
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FictitiousAPI from "../api";
import { AnalyticsService } from "../services/AnalyticsService";
import AuthService from "../services/AuthService";

export const DemoUserSignUp = (props: {
    onAuth: () => void;
}) => {
    const navigate = useNavigate();

    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [termsChecked, setTermsChecked] = useState<boolean>(false);

    const signup = async () => {
        if (!email || !password || !name) return setError("Please enter your name, email address, and password.");
        if (!email.includes("@")) return setError("Please enter a valid email address.");
        // if (!email.endsWith(".edu")) return setError("Please enter a valid .edu email address. Only .edu addresses are allowed for demo accounts.");
        if (password.length < 7 || password.length > 19) return setError("Please enter a password that is between 6 and 20 characters long.");
        if (name.trim().length < 1 || name.trim().length > 100) return setError("Please enter a name that is between 1 and 100 characters long.");
        if (!name.trim().includes(" ")) return setError("Please enter your full name (first and last, separated by a single space).");

        setLoading(true);
        try {
            AnalyticsService.trackDemoSignup(name, email);
            const response = await FictitiousAPI.accounts.registerDemoUserUsingPost({ emailAddress: email, password, fullName: name });
            if (response.status == 200 && response.data.demoLink) {
                const loginResponse = await FictitiousAPI.accounts.loginUsingPost({ email, password });
                if (loginResponse.data.success) {
                    FictitiousAPI.setSecurityData({ method: 'Local', token: loginResponse.data.token as any as string });
                    AuthService.storeToken(loginResponse.data.token as any as string);
                    setLoading(false);
                    (window as any).intercomSettings = {
                        api_base: "https://api-iam.intercom.io",
                        app_id: "noapcxie",
                        email: email,
                        name: name,
                        user_id: loginResponse.data.userAccount?.id,
                    };
                    props.onAuth();
                    navigate("/tech-demo");
                } else {
                    setError("An unexpected error occurred while creating your account. Please contact us for help.");
                }
            } else if (response.status == 409) {
                setError("That email address is already in use. Please log in or contact us for help.");
            } else {
                setError("An error occurred while creating your account. Please contact us for help.");
            }
        } catch (e) {
            setError("An error occurred while creating your account. Please contact us for help.");
        } finally {
            setTimeout(() => setLoading(false), 250);
        }
    };

    return <><TopNavigation
        identity={{
            href: '/demo-user-signup',
            title: "FictitiousAI Demo"
        }}
        utilities={[
            {
                type: "button",
                text: "Contact Support",
                href: "mailto:support@fictitious.ai",
                external: true,
                externalIconAriaLabel: " (opens in a new tab)"
            },
            {
                type: "menu-dropdown",
                text: "Sign In",
                description: "Log in to use FictitiousAI.",
                iconName: "user-profile",
                items: [
                    { id: "signin", text: "Sign in", href: "/", external: false }
                ]
            }
        ]}
        i18nStrings={{
            searchIconAriaLabel: "Search",
            searchDismissIconAriaLabel: "Close search",
            overflowMenuTriggerText: "More",
            overflowMenuTitleText: "All",
            overflowMenuBackIconAriaLabel: "Back",
            overflowMenuDismissIconAriaLabel: "Close menu"
        }}

    />
        <AppLayout disableContentPaddings={false}
            content={<ContentLayout
                header={
                    <SpaceBetween size="m">
                        <Header
                            variant="h1"
                            description="You can sign up for a demo account by entering your information below. Demo accounts can use an interactive demo of our detection algorithms and view example data from our Canvas integration."
                        >
                            Welcome to FictitiousAI
                        </Header>
                    </SpaceBetween>
                }
            >

                <Container
                    header={
                        <SpaceBetween size="s">
                            {error && <Alert type="error" dismissible onDismiss={() => setError(null)} >{error}</Alert>}
                            <Header
                                variant="h2"
                                description="Please enter your information below. You must sign up with a valid .edu email address and your full name to access the demo."
                                actions={<Button variant="link" href="mailto:support@fictitious.ai" disabled={loading}>Need help?</Button>}
                            >
                                Create a Demo Acount
                            </Header>
                        </SpaceBetween>
                    }
                >
                    <SpaceBetween size="m">
                        <FormField label="Full Name*">
                            <Input
                                type='text'
                                disabled={loading}
                                placeholder="Enter your full name."
                                value={name}
                                onChange={(e) => setName(e.detail.value)}
                            />
                        </FormField>
                        <FormField label="Email Address*">
                            <Input
                                type='email'
                                disabled={loading}
                                placeholder="Email must be a valid .edu address."
                                value={email}
                                onChange={(e) => setEmail(e.detail.value)}
                            />
                        </FormField>

                        <FormField label="Password*">
                            <Input
                                type='password'
                                disabled={loading}
                                placeholder="Password must be between 6 and 20 characters long."
                                value={password}
                                onChange={(e) => setPassword(e.detail.value)}
                            />
                        </FormField>
                        <FormField label="Terms and Conditions*">
                            <Checkbox
                                checked={termsChecked}
                                disabled={loading}
                                onChange={(e) => setTermsChecked(e.detail.checked)}>I am using the FictitiousAI Demo for educational purposes.</Checkbox>
                        </FormField>
                        <SpaceBetween direction="horizontal" size="m">
                            <Button variant="primary" loading={loading} disabled={loading || (!email || !name || !termsChecked || !password)} onClick={signup}>Sign Up</Button>
                            <Button variant="link" href="/" disabled={loading}>Already have an account?</Button>
                        </SpaceBetween>
                    </SpaceBetween>
                </Container>
            </ContentLayout>
            }
            navigationHide={true}
            navigationOpen={false}
            toolsHide={true}
        />
    </>
}