/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateOrganizationRequest,
  CreateOrganizationResponse,
  GetOrganizationResponse,
  ListOrganizationsResponse,
  ProvisionDemoUserRequest,
  ProvisionDemoUserResponse,
  ProvisionUsersRequest,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Admin<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags admin-server
   * @name ListOrganizationsResponseUsingGet
   * @summary listOrganizationsResponse
   * @request GET:/admin/organizations
   */
  listOrganizationsResponseUsingGet = (params: RequestParams = {}) =>
    this.request<ListOrganizationsResponse, void>({
      path: `/admin/organizations`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-server
   * @name CreateOrganizationUsingPost
   * @summary createOrganization
   * @request POST:/admin/organizations
   */
  createOrganizationUsingPost = (request: CreateOrganizationRequest, params: RequestParams = {}) =>
    this.request<CreateOrganizationResponse, void>({
      path: `/admin/organizations`,
      method: "POST",
      body: request,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-server
   * @name GetOrganizationUsingGet
   * @summary getOrganization
   * @request GET:/admin/organizations/{organizationId}
   */
  getOrganizationUsingGet = (organizationId: string, params: RequestParams = {}) =>
    this.request<GetOrganizationResponse, void>({
      path: `/admin/organizations/${organizationId}`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-server
   * @name ProvisionDemoUserUsingPost
   * @summary provisionDemoUser
   * @request POST:/admin/provisionDemoUser
   */
  provisionDemoUserUsingPost = (request: ProvisionDemoUserRequest, params: RequestParams = {}) =>
    this.request<ProvisionDemoUserResponse, void>({
      path: `/admin/provisionDemoUser`,
      method: "POST",
      body: request,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags admin-server
   * @name ProvisionOrganizationUsersUsingPost
   * @summary provisionOrganizationUsers
   * @request POST:/admin/provisionOrganizationUsers
   */
  provisionOrganizationUsersUsingPost = (request: ProvisionUsersRequest, params: RequestParams = {}) =>
    this.request<GetOrganizationResponse, void>({
      path: `/admin/provisionOrganizationUsers`,
      method: "POST",
      body: request,
      type: ContentType.Json,
      ...params,
    });
}
