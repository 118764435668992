import { Accounts } from "./lib/Accounts";
import { Admin } from "./lib/Admin";
import { Domain } from "./lib/Domain";

import { HttpClient } from "./lib/http-client";

export { HttpClient };
export * from "./lib/data-contracts";

export default class FictitiousAPI {
    public static securityData: {
        method: string;
        token: string;
    };

    public static setSecurityData(data: { method: string, token: string }) {
        FictitiousAPI.securityData = data;
    }

    public static accounts: Accounts;
    public static admin: Admin;
    public static domain: Domain;

    public static config: {
        baseUrl: string,
        baseApiParams: {
            format: "json" | "arrayBuffer" | "blob" | "text" | "formData",
            headers: any
        },
        securityWorker: any
    };

    public static setConfig(config: any) {
        FictitiousAPI.config = config;
        FictitiousAPI.accounts = new Accounts(FictitiousAPI.config);
        FictitiousAPI.admin = new Admin(FictitiousAPI.config);
        FictitiousAPI.domain = new Domain(FictitiousAPI.config);
    }
}
