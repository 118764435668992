import Header from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import './App.css';
import AuthService from "./services/AuthService";
import { CONFIG } from "./config";
import { useEffect, useState } from "react";
import { Login } from "./screens/Login";
import { CoursesHome } from "./screens/CoursesHome";
import { SubmissionsHome } from "./screens/SubmissionsHome";
import { AssignmentsHome } from "./screens/AssignmentsHome";
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import { NavWrapper } from "./navigation/NavWrapper";
import { AssignmentDetails } from "./screens/AssignmentDetails";
import { SubmissionDetails } from "./screens/SubmissionDetails";
import { FictitiousApiWrapper } from "./services/FictitiousApiWrapper";
import FictitiousAPI from "./api";
import { Home } from "./screens/Home";
import { CourseDetails } from "./screens/CourseDetails";
import { Logout } from "./components/Logout";
import { TechDemo } from "./screens/TechDemo";
import { DemoTokenCaptor } from "./components/DemoTokenCaptor";
import { Alert } from "@cloudscape-design/components";
import { AdminProvisionUser } from "./screens/AdminProvisionUser";
import { AdminTokenCaptor } from "./components/AdminTokenCaptor";
import { DemoUserSignUp } from "./screens/DemoUserSignUp";
import { CanvasOAuthTokenCaptor } from "./components/CanvasOAuthTokenCaptor";
import { ConfigureCanvas } from "./screens/ConfigureCanvas";
import { Integrations } from "./screens/Integrations";
import { ConfigureClassrooms } from "./screens/ConfigureClassrooms";

const securityWorker = () => {
  if (FictitiousAPI.securityData) {
    return {
      format: "json",
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": FictitiousAPI.securityData.token,
      },
    };
  }
};

const customFetch = (...fetchParams: Parameters<typeof fetch>) => {
  console.log(fetchParams);
  const baseURL = CONFIG.baseUrl;

  const url = fetchParams[0];
  const fetchInstance = fetch(...fetchParams);

  fetchInstance.then((response) => {
    const requestUrl = fetchParams[0];
    if (response.status === 401 || response.status === 403) {
      AuthService.clearStoredCredentials();
      window.location.reload();
    }
  });

  return fetchInstance;
};

FictitiousAPI.setConfig({
  baseUrl: CONFIG.baseUrl,
  baseApiParams: {
    format: "json",
    headers: {
      "Content-Type": "application/json",
    },
  },
  securityWorker: securityWorker,
  customFetch: customFetch,
});

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export const App = () => {
  const [auth, setAuth] = useState<boolean>(false);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [windowMessage, setWindowMessage] = useState<string | null>(null);

  useEffect(() => {
    if (AuthService.storedCredentialsAvailable()) {
      const token = AuthService.getStoredToken();
      try {
        FictitiousAPI.setSecurityData({ method: 'Local', token: token });
        FictitiousAPI.accounts.getUserUsingGet().then(result => {
          if (result.status == 200) {
            setAuth(true);
          } else {
            setAuth(false);
          }
        });
      } catch (e) {
        setAuth(false);
      }
    }
  }, []);

  useEffect(() => {
    if (windowDimensions) {
      if (windowDimensions.width < 768) {
        setWindowMessage("This application is not designed for mobile devices. Please use a desktop or laptop computer for the bext experience.");
      }
    }
  }, [windowDimensions]);

  const getAuthRoutes = () => (
    <>
      <Route path="/" element={<NavWrapper><Home /></NavWrapper>} />
      <Route path="/tech-demo" element={<NavWrapper isDemo={true} ><TechDemo /></NavWrapper>} />
      <Route path="/admin-provision-user" element={<NavWrapper><AdminProvisionUser /></NavWrapper>} />
      <Route path="/courses" element={<NavWrapper><CoursesHome /></NavWrapper>} />
      <Route path="/courses/:courseId" element={<NavWrapper><CourseDetails /></NavWrapper>} />
      <Route path="/courses/:courseId/assignments" element={<NavWrapper><AssignmentsHome /></NavWrapper>} />
      <Route path="/assignments" element={<NavWrapper><AssignmentsHome /></NavWrapper>} />
      <Route path="/courses/:courseId/assignments/:assignmentId" element={<NavWrapper><AssignmentDetails /></NavWrapper>} />
      <Route path="/courses/:courseId/assignments/:assignmentId/submissions" element={<NavWrapper><SubmissionsHome /></NavWrapper>} />
      <Route path="/courses/:courseId/assignments/:assignmentId/submissions/:submissionId" element={<NavWrapper><SubmissionDetails /></NavWrapper>} />
      <Route path="/integrations" element={<NavWrapper><Integrations /></NavWrapper>} />
      <Route path="/integrations/canvas" element={<NavWrapper><ConfigureCanvas /></NavWrapper>} />
      <Route path="/integrations/classroom" element={<NavWrapper><ConfigureClassrooms /></NavWrapper>} />
    </>
  );

  const getNoAuthRoutes = () => (
    <>
      <Route path="/" element={<Login onAuth={() => setAuth(true)} />} />
      <Route path="/initiate-demo/:token" element={<DemoTokenCaptor />} />
      <Route path="/initiate-admin/:token" element={<AdminTokenCaptor />} />
      <Route path="/demo-user-signup" element={<DemoUserSignUp onAuth={() => setAuth(true)} />} />
    </>
  );

  const getSharedRoutes = () => (<>
    <Route path="/logout" element={<Logout />} />
    <Route path="/canvasOAuthCallback" element={<CanvasOAuthTokenCaptor />} />
    <Route path="*" element={<Navigate to="/" replace />} />
  </>
  );

  return <>
    {windowMessage && <div style={{ position: 'absolute', zIndex: 100000, padding: 10 }}> <Alert type="error" onDismiss={() => setWindowMessage(null)} dismissible>{windowMessage}</Alert></div>}
    <BrowserRouter>
      <Routes>
        {auth ? getAuthRoutes() : getNoAuthRoutes()}
        {getSharedRoutes()}
      </Routes></BrowserRouter ></>
};


export default App;