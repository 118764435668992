import { Container, Header, Spinner } from "@cloudscape-design/components";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import FictitiousAPI from "../api";
import AuthService from "../services/AuthService";

export const AdminTokenCaptor = () => {
    const { token } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        FictitiousAPI.setSecurityData({ method: 'Local', token: token as any as string });
        AuthService.storeToken(token as any as string);
        navigate("/admin-provision-user");
    }, [token]);

    return <Container>
        <Spinner />
        <Header>Starting admin view...</Header>
    </Container>
}