import { ContentLayout, SpaceBetween, Header, Button, Container, Table, StatusIndicator, ColumnLayout, Spinner, BreadcrumbGroup } from "@cloudscape-design/components";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FictitiousAPI, { AssignmentObject, CourseObject, GetCourseResponse } from "../api";
import { KeyValue } from "../components/KeyValue";
import { FictitiousApiWrapper } from "../services/FictitiousApiWrapper";
import { formatDate } from "../util/FormatUtil";

export const CoursesHome = () => {
    const navigate = useNavigate();
    const [courses, setCourses] = useState<CourseObject[]>([]);

    useEffect(() => {
        loadCourses();
    }, []);

    const loadCourses = async () => {
        const courses = await FictitiousAPI.domain.listCoursesUsingGet();
        setCourses(courses.data.courses.sort((a, b) => a.courseCode && b.courseCode ? a.courseCode.localeCompare(b.courseCode) : a.name.localeCompare(b.name)));
    }

    return <ContentLayout
        header={
            <SpaceBetween size="m">
                <BreadcrumbGroup
                    onClick={(e) => { e.preventDefault(); navigate(e.detail.href) }}
                    items={[
                        { text: "Home", href: "/" },
                        { text: "Courses", href: "/courses" },
                    ]}
                    ariaLabel="Breadcrumbs"
                />
                <div></div>
                <Header
                    variant="h1"
                    description="Click on a course to see details, assignments, and students."
                // actions={
                //     <Button variant="primary">Sync from LMS</Button>
                // }
                >
                    Courses
                </Header>
            </SpaceBetween>
        }
    >
        <Container>
            {!courses && <Spinner />}
            <Table
                empty={<div>No Courses</div>}
                items={courses}
                onRowClick={(row) => navigate("/courses/" + (row.detail.item as any).id)}
                columnDefinitions={
                    [{
                        id: 'name',
                        header: 'Course Name',
                        cell: (item: CourseObject) => <span style={{ cursor: 'pointer' }}>{item?.name || "-"}</span>
                    },
                    {
                        id: 'courseCode',
                        header: 'Course Code',
                        cell: (item: CourseObject) => item?.courseCode || "-"
                    },
                    {
                        id: 'description',
                        header: 'Description',
                        cell: (item: CourseObject) => item?.description || "-"
                    }
                    ]
                }
            />
        </Container>
    </ContentLayout>
}