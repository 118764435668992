/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CanvasOAuthLoginRequest,
  CanvasOAuthLoginStartOAuthFlowResponse,
  ConfigureCanvasOAuthRequest,
  CreateDemoSubmissionRequest,
  CreateManualAssignmentRequest,
  CreateManualAssignmentResponse,
  CreateManualCourseRequest,
  CreateManualCourseResponse,
  CreateManualStudentsRequest,
  CreateManualStudentsResponse,
  CreateManualSubmissionRequest,
  CreateManualSubmissionResponse,
  GetAssignmentResponse,
  GetCourseResponse,
  GetMediaUploadLinkResponse,
  GetSubmissionResponse,
  ListAssignmentsResponse,
  ListCoursesResponse,
  ListStudentsResponse,
  ListSubmissionsResponse,
  LmsOAuthConfigObject,
  LmsOAuthCredentialObject,
  StudentObject,
  SyncFromLmsResponse,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Domain<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags domain-server
   * @name CreateManualAssignmentUsingPost
   * @summary createManualAssignment
   * @request POST:/domain/assignment
   */
  createManualAssignmentUsingPost = (
    request: CreateManualAssignmentRequest,
    query?: {
      authenticated?: boolean;
      "authorities[0].authority"?: string;
      credentials?: object;
      details?: object;
      principal?: object;
    },
    params: RequestParams = {},
  ) =>
    this.request<CreateManualAssignmentResponse, void>({
      path: `/domain/assignment`,
      method: "POST",
      query: query,
      body: request,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags domain-server
   * @name ListAssignmentsUsingGet
   * @summary listAssignments
   * @request GET:/domain/assignments
   */
  listAssignmentsUsingGet = (
    query?: {
      authenticated?: boolean;
      "authorities[0].authority"?: string;
      credentials?: object;
      details?: object;
      principal?: object;
    },
    params: RequestParams = {},
  ) =>
    this.request<ListAssignmentsResponse, void>({
      path: `/domain/assignments`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags domain-server
   * @name GetAssignmentUsingGet
   * @summary getAssignment
   * @request GET:/domain/assignments/{assignmentId}
   */
  getAssignmentUsingGet = (
    assignmentId: string,
    query?: {
      authenticated?: boolean;
      "authorities[0].authority"?: string;
      credentials?: object;
      details?: object;
      principal?: object;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetAssignmentResponse, void>({
      path: `/domain/assignments/${assignmentId}`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags domain-server
   * @name ConfigureCanvasCredentialUsingPost
   * @summary configureCanvasCredential
   * @request POST:/domain/canvasOAuthLogin
   */
  configureCanvasCredentialUsingPost = (
    request: CanvasOAuthLoginRequest,
    query?: {
      authenticated?: boolean;
      "authorities[0].authority"?: string;
      credentials?: object;
      details?: object;
      principal?: object;
    },
    params: RequestParams = {},
  ) =>
    this.request<LmsOAuthCredentialObject, void>({
      path: `/domain/canvasOAuthLogin`,
      method: "POST",
      query: query,
      body: request,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags domain-server
   * @name StartOAuthFlowUsingGet
   * @summary startOAuthFlow
   * @request GET:/domain/canvasOAuthLoginStartOAuthFlow
   */
  startOAuthFlowUsingGet = (
    query?: {
      authenticated?: boolean;
      "authorities[0].authority"?: string;
      credentials?: object;
      details?: object;
      principal?: object;
    },
    params: RequestParams = {},
  ) =>
    this.request<CanvasOAuthLoginStartOAuthFlowResponse, void>({
      path: `/domain/canvasOAuthLoginStartOAuthFlow`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags domain-server
   * @name CreateConfigurationUsingPost
   * @summary createConfiguration
   * @request POST:/domain/configureCanvasOAuth
   */
  createConfigurationUsingPost = (
    request: ConfigureCanvasOAuthRequest,
    query?: {
      authenticated?: boolean;
      "authorities[0].authority"?: string;
      credentials?: object;
      details?: object;
      principal?: object;
    },
    params: RequestParams = {},
  ) =>
    this.request<LmsOAuthConfigObject, void>({
      path: `/domain/configureCanvasOAuth`,
      method: "POST",
      query: query,
      body: request,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags domain-server
   * @name CreateManualCourseUsingPost
   * @summary createManualCourse
   * @request POST:/domain/course
   */
  createManualCourseUsingPost = (
    request: CreateManualCourseRequest,
    query?: {
      authenticated?: boolean;
      "authorities[0].authority"?: string;
      credentials?: object;
      details?: object;
      principal?: object;
    },
    params: RequestParams = {},
  ) =>
    this.request<CreateManualCourseResponse, void>({
      path: `/domain/course`,
      method: "POST",
      query: query,
      body: request,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags domain-server
   * @name GetCourseInfoUsingPost
   * @summary getCourseInfo
   * @request POST:/domain/course/{courseId}
   */
  getCourseInfoUsingPost = (
    courseId: string,
    query?: {
      authenticated?: boolean;
      "authorities[0].authority"?: string;
      credentials?: object;
      details?: object;
      principal?: object;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetCourseResponse, void>({
      path: `/domain/course/${courseId}`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags domain-server
   * @name ListAssignmentsForCourseUsingGet
   * @summary listAssignmentsForCourse
   * @request GET:/domain/courseAssignments/{courseId}
   */
  listAssignmentsForCourseUsingGet = (
    courseId: string,
    query?: {
      authenticated?: boolean;
      "authorities[0].authority"?: string;
      credentials?: object;
      details?: object;
      principal?: object;
    },
    params: RequestParams = {},
  ) =>
    this.request<ListAssignmentsResponse, void>({
      path: `/domain/courseAssignments/${courseId}`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags domain-server
   * @name ListCoursesUsingGet
   * @summary listCourses
   * @request GET:/domain/courses
   */
  listCoursesUsingGet = (
    query?: {
      authenticated?: boolean;
      "authorities[0].authority"?: string;
      credentials?: object;
      details?: object;
      principal?: object;
    },
    params: RequestParams = {},
  ) =>
    this.request<ListCoursesResponse, void>({
      path: `/domain/courses`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags domain-server
   * @name CreateDemoSubmissionUsingPost
   * @summary createDemoSubmission
   * @request POST:/domain/demoSubmissions
   */
  createDemoSubmissionUsingPost = (
    request: CreateDemoSubmissionRequest,
    query?: {
      authenticated?: boolean;
      "authorities[0].authority"?: string;
      credentials?: object;
      details?: object;
      principal?: object;
    },
    params: RequestParams = {},
  ) =>
    this.request<CreateManualSubmissionResponse, void>({
      path: `/domain/demoSubmissions`,
      method: "POST",
      query: query,
      body: request,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags domain-server
   * @name GetDemoSubmissionByIdUsingGet
   * @summary getDemoSubmissionById
   * @request GET:/domain/demoSubmissionsById/{submissionId}
   */
  getDemoSubmissionByIdUsingGet = (
    submissionId: string,
    query?: {
      authenticated?: boolean;
      "authorities[0].authority"?: string;
      credentials?: object;
      details?: object;
      principal?: object;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetSubmissionResponse, void>({
      path: `/domain/demoSubmissionsById/${submissionId}`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags domain-server
   * @name GetConfigurationUsingGet
   * @summary getConfiguration
   * @request GET:/domain/getLmsConfig
   */
  getConfigurationUsingGet = (
    query?: {
      authenticated?: boolean;
      "authorities[0].authority"?: string;
      credentials?: object;
      details?: object;
      principal?: object;
    },
    params: RequestParams = {},
  ) =>
    this.request<LmsOAuthConfigObject, void>({
      path: `/domain/getLmsConfig`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags domain-server
   * @name GetMediaUploadLinkUsingGet
   * @summary getMediaUploadLink
   * @request GET:/domain/getUploadLink
   */
  getMediaUploadLinkUsingGet = (
    query?: {
      authenticated?: boolean;
      "authorities[0].authority"?: string;
      credentials?: object;
      details?: object;
      principal?: object;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetMediaUploadLinkResponse, void>({
      path: `/domain/getUploadLink`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags domain-server
   * @name CreateManualStudentsUsingPost
   * @summary createManualStudents
   * @request POST:/domain/students
   */
  createManualStudentsUsingPost = (
    request: CreateManualStudentsRequest,
    query?: {
      authenticated?: boolean;
      "authorities[0].authority"?: string;
      credentials?: object;
      details?: object;
      principal?: object;
    },
    params: RequestParams = {},
  ) =>
    this.request<CreateManualStudentsResponse, void>({
      path: `/domain/students`,
      method: "POST",
      query: query,
      body: request,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags domain-server
   * @name GetStudentUsingGet
   * @summary getStudent
   * @request GET:/domain/students/{studentId}
   */
  getStudentUsingGet = (
    studentId: string,
    query?: {
      authenticated?: boolean;
      "authorities[0].authority"?: string;
      credentials?: object;
      details?: object;
      principal?: object;
    },
    params: RequestParams = {},
  ) =>
    this.request<StudentObject, void>({
      path: `/domain/students/${studentId}`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags domain-server
   * @name GetStudentsInCourseUsingGet
   * @summary getStudentsInCourse
   * @request GET:/domain/studentsInCourse/{courseId}
   */
  getStudentsInCourseUsingGet = (
    courseId: string,
    query?: {
      authenticated?: boolean;
      "authorities[0].authority"?: string;
      credentials?: object;
      details?: object;
      principal?: object;
    },
    params: RequestParams = {},
  ) =>
    this.request<ListStudentsResponse, void>({
      path: `/domain/studentsInCourse/${courseId}`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags domain-server
   * @name CreateManualSubmissionUsingPost
   * @summary createManualSubmission
   * @request POST:/domain/submissions
   */
  createManualSubmissionUsingPost = (
    request: CreateManualSubmissionRequest,
    query?: {
      authenticated?: boolean;
      "authorities[0].authority"?: string;
      credentials?: object;
      details?: object;
      principal?: object;
    },
    params: RequestParams = {},
  ) =>
    this.request<CreateManualSubmissionResponse, void>({
      path: `/domain/submissions`,
      method: "POST",
      query: query,
      body: request,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags domain-server
   * @name ListSubmissionsUsingGet
   * @summary listSubmissions
   * @request GET:/domain/submissions/{assignmentId}
   */
  listSubmissionsUsingGet = (
    assignmentId: string,
    query?: {
      authenticated?: boolean;
      "authorities[0].authority"?: string;
      credentials?: object;
      details?: object;
      principal?: object;
    },
    params: RequestParams = {},
  ) =>
    this.request<ListSubmissionsResponse, void>({
      path: `/domain/submissions/${assignmentId}`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags domain-server
   * @name GetSubmissionUsingGet
   * @summary getSubmission
   * @request GET:/domain/submissions/{assignmentId}/{submissionId}
   */
  getSubmissionUsingGet = (
    assignmentId: string,
    submissionId: string,
    query?: {
      authenticated?: boolean;
      "authorities[0].authority"?: string;
      credentials?: object;
      details?: object;
      principal?: object;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetSubmissionResponse, void>({
      path: `/domain/submissions/${assignmentId}/${submissionId}`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags domain-server
   * @name SyncFromLmsUsingPost
   * @summary syncFromLms
   * @request POST:/domain/syncFromLms
   */
  syncFromLmsUsingPost = (
    query?: {
      authenticated?: boolean;
      "authorities[0].authority"?: string;
      credentials?: object;
      details?: object;
      principal?: object;
    },
    params: RequestParams = {},
  ) =>
    this.request<SyncFromLmsResponse, void>({
      path: `/domain/syncFromLms`,
      method: "POST",
      query: query,
      type: ContentType.Json,
      ...params,
    });
}
