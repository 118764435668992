import { Alert, Box, Button, Container, ExpandableSection, FormField, Header, ProgressBar, SpaceBetween, Spinner, Tabs, Textarea, Wizard } from "@cloudscape-design/components"
import { useState } from "react";
import FictitiousAPI, { SubmissionObject } from "../api";
import Dropzone from "react-dropzone";
import useInterval from "@use-it/interval";
import { AnalyticsService } from "../services/AnalyticsService";
import { EssayResults } from "../components/EssayResults";
// @ts-ignore
import mixpanel from 'mixpanel-browser';

mixpanel.init('d0af73fac58de1f5fa913881401d32df');

export const TechDemo = () => {
    const [activeStepIndex, setActiveStepIndex] = useState<number>(0);

    const [submission, setSubmission] = useState<SubmissionObject | null>(null);
    const [uploadFile, setUploadFile] = useState<File | null>(null);
    const [inputText, setInputText] = useState<string | undefined>(undefined);
    const [userFeedback, setUserFeedback] = useState<string>("");

    const [createStep, setCreateStep] = useState<number>(0);

    const [error, setError] = useState<string | null>(null);

    const renderResults = () => {
        return <EssayResults submission={submission!} />
    }

    const createSubmission = async () => {
        setCreateStep(0);
        let submissionText: Promise<ArrayBuffer | string>;
        if (uploadFile) {
            submissionText = new Promise((resolve, reject) => {
                const fileContents = new FileReader();
                fileContents.readAsArrayBuffer(uploadFile!);
                fileContents.onload = () => resolve(fileContents.result as ArrayBuffer);
                fileContents.onerror = () => reject();
            });
        } else if (inputText) {
            submissionText = new Promise((resolve, reject) => resolve(inputText));
        } else {
            console.warn("Failed to get input type");
            return;
        }
        const uploadRequest = await FictitiousAPI.domain.getMediaUploadLinkUsingGet();
        const link = uploadRequest.data.uploadLink;
        setCreateStep(1);
        await fetch(link, {
            method: 'PUT',
            body: (await submissionText) as any
        });
        setCreateStep(2);
        const submissionResponse = await FictitiousAPI.domain.createDemoSubmissionUsingPost({
            submissionUploadKey: uploadRequest.data.uploadKey,
        });
        const user = await FictitiousAPI.accounts.getUserUsingGet();
        AnalyticsService.trackDemoInitialization(submissionResponse.data.submissionObject.id + " user " + (user.data.fullName || ""));
        setSubmission(submissionResponse.data.submissionObject);
        mixpanel.track('CreateSubmission', { id: submissionResponse.data.submissionObject.id, fullName: (user.data.fullName || "") });
    }

    const pollSubmission = async () => {
        if (!submission || submission.predictionState === 'COMPLETE' || submission.predictionState === 'FAILURE') {
            return new Promise((resolve, reject) => resolve(null));
        }
        const submissionResponse = await FictitiousAPI.domain.getDemoSubmissionByIdUsingGet(submission.id);
        setSubmission(submissionResponse.data.submissionObject);
        // calculate progress indicator state
        const millisecondsSinceLastUpdate = new Date().getTime() - submissionResponse.data.submissionObject.submissionTime.getTime();
        const secondsSinceCreation = millisecondsSinceLastUpdate / 1000;
        const expectedSeconds = 30;
        const numSteps = 8;
        const expectedSecondsPerStep = expectedSeconds / numSteps;
        const currentStep = Math.min(9, 2 + Math.floor(secondsSinceCreation / expectedSecondsPerStep));
        setCreateStep(currentStep);
        if (submissionResponse.data.submissionObject.predictionState === 'COMPLETE' || submissionResponse.data.submissionObject.predictionState === 'FAILURE') {
            setActiveStepIndex(3);
        }
        return submissionResponse;
    }

    const createSteps = [
        {
            value: 0,
            label: "Step 1 of 3",
            description: "Uploading your input file for analysis..."
        },
        {
            value: 10,
            label: "Step 1 of 3",
            description: "Uploading your input file for analysis..."
        },
        {
            value: 25,
            label: "Step 2 of 3",
            description: "Analyzing your input file to detect AI-written text..."
        },
        {
            value: 35,
            label: "Step 2 of 3",
            description: "Analyzing your input file to detect AI-written text..."
        },
        {
            value: 45,
            label: "Step 2 of 3",
            description: "Analyzing your input file to detect AI-written text..."
        },
        {
            value: 55,
            label: "Step 2 of 3",
            description: "Analyzing your input file to detect AI-written text..."
        },
        {
            value: 65,
            label: "Step 2 of 3",
            description: "Analyzing your input file to detect AI-written text..."
        },
        {
            value: 75,
            label: "Step 2 of 3",
            description: "Analyzing your input file to detect AI-written text..."
        },
        {
            value: 85,
            label: "Step 2 of 3",
            description: "Analyzing your input file to detect AI-written text..."
        },
        {
            value: 95,
            label: "Step 3 of 3",
            description: "This is taking longer than usual, please be patient..."
        }
    ];

    useInterval(() => { pollSubmission() }, 5000);

    const resetWizard = () => {
        setActiveStepIndex(0);
        setError(null);
        setUploadFile(null);
        setInputText(undefined);
        setSubmission(null);
        setCreateStep(0);
        setUserFeedback("");
    }

    return <>
        <SpaceBetween size="m">
            <Alert
                statusIconAriaLabel="Info"
                header="Tech Demo Mode"
                action={<Button href="https://forms.gle/26pQCfSgtySq71a4A" target="_blank">Request Full Access</Button>}
            >
                You're currently in tech demo mode! Note that this is not a full-featured version of our product, and
                that your results will be limited to prevent reverse engineering.
            </Alert>
            <Wizard
                i18nStrings={{
                    stepNumberLabel: stepNumber =>
                        `Step ${stepNumber}`,
                    collapsedStepsLabel: (stepNumber, stepsCount) =>
                        `Step ${stepNumber} of ${stepsCount}`,
                    navigationAriaLabel: "Steps",
                    cancelButton: "Cancel",
                    previousButton: "Previous",
                    nextButton: "Next",
                    submitButton: "Back to Start",
                    optional: "optional"
                }}
                onNavigate={({ detail }) => {
                    if (detail.reason === 'step') return;

                    if (detail.requestedStepIndex === 1) {
                        // validate input
                        if (!uploadFile && !inputText) {
                            setError("You must select a file or enter raw text to continue.");
                            return;
                        } if (uploadFile && uploadFile.size > 100_000) {
                            setError("You must select a file less than 100KB to continue.");
                            return;
                        } if (inputText && inputText.split(' ').length < 10) {
                            setError("You must enter at least 10 words to continue.");
                            return;
                        }
                        if (inputText && inputText.length > 10_000) {
                            setError("You must enter less than 10,000 characters to use the tech demo.");
                            return;
                        }
                    }

                    if (detail.requestedStepIndex === 2 && detail.reason === 'previous') {
                        setActiveStepIndex(0);
                        resetWizard();
                        return;
                    }

                    setActiveStepIndex(detail.requestedStepIndex);
                    if (detail.requestedStepIndex === 2 && detail.reason === 'next' && !submission) {
                        createSubmission();
                    }
                }}
                onCancel={() => {
                    if (activeStepIndex !== 2)
                        resetWizard(); else {
                        setError("You cannot cancel right now! Please wait for the submission to complete.");
                    }
                }}
                onSubmit={() => {
                    if (userFeedback && submission) {
                        AnalyticsService.sendDemoFeedback(submission?.id, userFeedback);
                    }
                    resetWizard();
                }}
                activeStepIndex={activeStepIndex}
                isLoadingNextStep={activeStepIndex === 2 ? (
                    submission === null || submission?.predictionState === 'PENDING' || submission?.predictionState === 'IN_PROGRESS'
                ) : false}
                steps={[
                    {
                        title: "Select or paste input",
                        description:
                            "You can upload files and try out a limited version of our platform from this page. Files will be deleted after 24 hours.",
                        content: (
                            <SpaceBetween size="s">
                                {error && <Alert
                                    statusIconAriaLabel="Error"
                                    header="Invalid Request"
                                    type="error"
                                    dismissible
                                    onDismiss={() => setError(null)}
                                >
                                    {error}
                                </Alert>}
                                <Container
                                    header={
                                        <Header variant="h2">
                                            Choose input type
                                        </Header>
                                    }
                                >
                                    You can either upload a file or enter raw text from the tabs below. We support PDF, DOCX, DOC, and TXT files.
                                    <Tabs
                                        onChange={(e) => {
                                            if (e.detail.activeTabId === 'upload') {
                                                setInputText(undefined);
                                            } else {
                                                setUploadFile(null);
                                            }
                                        }}
                                        tabs={[
                                            {
                                                id: 'upload',
                                                label: 'Upload',
                                                content: <Box>
                                                    <Dropzone
                                                        multiple={false}
                                                        accept={{ "application/pdf": [], "application/msword": [], "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [] }}
                                                        onDrop={acceptedFiles => setUploadFile(acceptedFiles[0])}>
                                                        {({ getRootProps, getInputProps }) => (
                                                            <section style={{ backgroundColor: '#EEE', padding: 50, borderRadius: 15 }}>
                                                                <div {...getRootProps()}>
                                                                    <input {...getInputProps()} />
                                                                    <Button>Drag a file here or click to select files</Button>
                                                                </div>
                                                            </section>
                                                        )}
                                                    </Dropzone>
                                                    {uploadFile &&
                                                        <Box>
                                                            <Header variant="h3">Selected File</Header>
                                                            <p>{uploadFile.name}</p>
                                                        </Box>
                                                    }
                                                </Box>
                                            },
                                            {
                                                id: 'paste',
                                                label: 'Paste',
                                                content: <Box><Textarea value={inputText || ""} onChange={(e) => setInputText(e.detail.value)} /></Box>
                                            }
                                        ]} />
                                </Container>
                            </SpaceBetween>
                        )
                    },
                    {
                        title: "Confirm submission",
                        description:
                            "Confirm that your text or file is correct, and then click 'Next' to begin processing.",
                        content: (
                            <SpaceBetween size="s">
                                {error && <Alert
                                    statusIconAriaLabel="Error"
                                    header="Invalid Request"
                                    type="error"
                                    dismissible
                                    onDismiss={() => setError(null)}
                                >
                                    {error}
                                </Alert>}
                                <Container>
                                    <Header variant="h2">Submission Details</Header>
                                    <p>Submission Type: {uploadFile ? "File" : "Text"}</p>
                                    {uploadFile && <p>File Name: {uploadFile.name}</p>}
                                    {uploadFile && <p>File Size: {uploadFile.size} bytes</p>}
                                    {inputText && <p>Text: {inputText}</p>}
                                </Container>
                            </SpaceBetween>
                        ),
                    },
                    {
                        title: "Wait for results",
                        description:
                            "We're processing your submission! This usually takes around 30 seconds, but may take longer for larger submissions or when our servers are busy.",
                        content: (
                            <Container>
                                <ProgressBar value={createSteps[createStep].value} label={createSteps[createStep].label} description={createSteps[createStep].description} />
                                <Spinner size='big' />
                            </Container>
                        ),
                    },
                    {
                        title: "View results",
                        description:
                            "The results shown below were generated with a smaller version of our regular model, and may not be as accurate as the full version.",
                        content: (
                            <Container
                                header={
                                    <Header variant="h2">
                                        Your Submission Results
                                    </Header>
                                }
                            >
                                <SpaceBetween direction="vertical" size="s">
                                    <Alert
                                        header="Understanding your results"
                                    >
                                        <ExpandableSection variant="footer" headerText="How do I read your visualizations? What do the colors mean?">
                                            We visualize results with four colors: green, yellow, orange, and red. These represent increasing likelihoods that the sentences or paragraphs they belong to were AI-generated.
                                            Our platform also outputs a "level of confidence" for each prediction, which corresponds to the transparency of the color. The higher the level of confidence, the stronger the color.
                                            {/* You can also click on a sentence or paragraph to see the level of confidence and probability associated with the prediction. */}
                                        </ExpandableSection>
                                        <ExpandableSection variant="footer" headerText="Why were my paragraph breaks modified?">
                                            In some cases, our model may rearrange paragraph breaks in your submission. This is done to help improve the accuracy of our predictions when your input data may have had abnormally short
                                            or long paragraphs.
                                        </ExpandableSection>
                                    </Alert>
                                    {renderResults()}
                                </SpaceBetween>
                            </Container>
                        ),
                    },
                    {
                        title: "Give us your feedback",
                        description:
                            "We'd love to have your feedback on our tech demo!",
                        content: (
                            <Container
                                header={
                                    <Header variant="h2">
                                        We'd love to hear your feedback!
                                    </Header>
                                }
                            >
                                <SpaceBetween direction="vertical" size="l">
                                    <FormField label="What did you think of our analysis?">
                                        <Textarea value={userFeedback} onChange={(e) => setUserFeedback(e.detail.value)} />
                                    </FormField>
                                </SpaceBetween>
                            </Container>
                        ),
                        isOptional: true
                    },
                ]}
            />
        </SpaceBetween>
    </>
}