import { Cards, Link, StatusIndicator } from "@cloudscape-design/components";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../util/FormatUtil";

export const Integrations = () => {
    const navigate = useNavigate();
    return <Cards items={[{
        id: "canvas",
        name: "Canvas LMS",
        description: "Integrate with Canvas LMS to automatically import courses, assignments, and student submissions.",
        link: '/integrations/canvas',
    }, {
        id: "classroom",
        name: "Google Classroom",
        description: "Integrate with Google Classroom to automatically import courses, assignments, and student submissions.",
        link: '/integrations/classroom',
    }]}
        cardDefinition={{
            header: e => {
                return e.name;
            },
            sections: [
                { id: 'description', header: "Description", content: e => e.description },
                { id: 'details', content: e => <Link onFollow={() => navigate(e.link)}>Install Now</Link> },
            ]
        }}
        cardsPerRow={[{ cards: 3 }]}
    />
}