import { Container, Header, Spinner } from "@cloudscape-design/components";
import { useEffect } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import FictitiousAPI from "../api";
import AuthService from "../services/AuthService";

export const CanvasOAuthTokenCaptor = () => {
    const [searchParams, _] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        const code = searchParams.get("code");
        const state = searchParams.get("state");

        if (!code || !state) {
            alert("Something went wrong. Please retry after logging in. Error code 0x000");
            navigate("/");
            return;
        }

        if (AuthService.storedCredentialsAvailable()) {
            const token = AuthService.getStoredToken();
            try {
                FictitiousAPI.setSecurityData({ method: 'Local', token: token });
                FictitiousAPI.accounts.getUserUsingGet().then(result => {
                    if (result.status == 200) {
                        // call Fictitious API with code and state
                        FictitiousAPI.domain.configureCanvasCredentialUsingPost({ temporaryCode: code })
                            .then(result => {
                                if (result.status == 200) {
                                    navigate("/courses");
                                } else {
                                    alert("Something went wrong. Please retry after logging in. Error code 0x004");
                                }
                            });
                    } else {
                        alert("Something went wrong. Please retry after logging in. Error code 0x001");
                    }
                });
            } catch (e) {
                alert("Something went wrong. Please retry after logging in. Error code 0x002");
            }
        } else {
            alert("Something went wrong. Please retry after logging in. Error code 0x003");
        }

        navigate("/");
    }, [searchParams]);

    return <Container>
        <Spinner />
        <Header>Starting admin view...</Header>
    </Container>
}