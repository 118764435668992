import { AppLayout, TopNavigation } from "@cloudscape-design/components";
import { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ServiceNavigation } from ".";
import FictitiousAPI, { UserAccount } from "../api";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export const NavWrapper = (props: { isDemo?: boolean; children: ReactNode }) => {
    const [navigationOpen, setNavigationOpen] = useState(true);

    const navigate = useNavigate();

    const [profile, setProfile] = useState<UserAccount | null>(null);

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        const fetchData = async () => {
            const profile = await FictitiousAPI.accounts.getUserUsingGet();
            setProfile(profile.data);
        }
        fetchData();
    }, []);


    useEffect(() => {
        if (windowDimensions) {
            if (windowDimensions.width < 768) {
                setNavigationOpen(false);
            }
        }
    }, [windowDimensions]);


    return <><TopNavigation
        identity={{
            href: window.location.href.includes('tech-demo') ? "/tech-demo" : "/",
            title: "FictitiousAI"
        }}
        utilities={[
            {
                type: "button",
                text: "Contact Support",
                href: "mailto:support@fictitious.ai",
                external: true,
                externalIconAriaLabel: " (opens in a new tab)"
            },
            {
                type: "menu-dropdown",
                text: profile?.fullName,
                description: profile?.email,
                iconName: "user-profile",
                onItemClick: (event) => {
                    if (event.detail.external) {
                        return;
                    } else {
                        event.preventDefault();
                        navigate(event.detail.href!);
                    }
                },
                items: [
                    {
                        id: "integrations",
                        text: "Integrations",
                        items: [
                            {
                                id: "canvas",
                                text: "Canvas",
                                href: "/integrations/canvas",
                                external: false
                            },
                            {
                                id: "classroom",
                                text: "Google Classroom",
                                href: "/integrations/classroom",
                                external: false
                            }
                        ]
                    },
                    {
                        id: "support-group",
                        text: "Support",
                        items: [
                            {
                                id: "documentation",
                                text: "Documentation",
                                href: "https://www.fictitious.ai/features",
                                external: true,
                                externalIconAriaLabel:
                                    " (opens in new tab)"
                            },
                            {
                                id: "email-help",
                                text: "Email Support",
                                href: "mailto:support@fictitious.ai",
                                external: true,
                                externalIconAriaLabel:
                                    " (opens in new tab)"
                            },
                            {
                                id: "feedback",
                                text: "Feedback",
                                href: "https://www.fictitious.ai/contact",
                                external: true,
                                externalIconAriaLabel:
                                    " (opens in new tab)"
                            }
                        ]
                    },
                    { id: "signout", text: "Sign out", href: "/logout", external: false }
                ]
            }
        ]}
        i18nStrings={{
            searchIconAriaLabel: "Search",
            searchDismissIconAriaLabel: "Close search",
            overflowMenuTriggerText: "More",
            overflowMenuTitleText: "All",
            overflowMenuBackIconAriaLabel: "Back",
            overflowMenuDismissIconAriaLabel: "Close menu"
        }}

    />
        <AppLayout disableContentPaddings={false}
            content={props.children}
            navigation={<ServiceNavigation isDemo={props.isDemo} />}
            navigationOpen={navigationOpen}
            onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
            toolsHide={true}
        />
    </>
}