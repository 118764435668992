import { Table, StatusIndicator } from "@cloudscape-design/components";
import { useNavigate } from "react-router-dom";
import { GetCourseResponse, StudentObject, SubmissionObject } from "../api";
import { formatDate } from "../util/FormatUtil";

export const SubmissionList = (props: {
    submissions: SubmissionObject[];
    course?: GetCourseResponse;
    assignmentId?: string;
    students: Map<string, StudentObject>;
}) => {
    const navigate = useNavigate();
    const { submissions, assignmentId, students, course } = props;
    return <Table
        items={submissions}
        empty={<div>No Submissions</div>}
        onRowClick={(item) => navigate(`/courses/${course?.courseObject.id}/assignments/${assignmentId}/submissions/${item.detail.item.id}`)}
        columnDefinitions={[
            {
                id: 'studentName',
                header: 'Student Name',
                cell: (item) => <span style={{ cursor: 'pointer' }}>{students.get(item.studentId)?.name || "Unknown Student"}</span>
            },
            {
                id: 'submissionTime',
                header: 'Submission Time',
                cell: (item) => formatDate(new Date(item?.submissionTime))
            },
            {
                id: 'plagiarismResult',
                header: 'Submission Integrity',
                cell: (item) => {
                    if (item.predictionState === 'PENDING') {
                        return <StatusIndicator type='in-progress'>In Progress</StatusIndicator>
                    }
                    let status;
                    let aggregateProb = item.predictionResultObject?.result?.aggregateFake || 0;
                    if (aggregateProb > 0.6) {
                        status = 'error';
                    } else {
                        status = 'success';
                    }
                    return <StatusIndicator type={status as any}>{(100 * aggregateProb).toFixed(2) + "% AI-generated"}</StatusIndicator>
                }
            }
        ]} />
}