import { Alert, BreadcrumbGroup, Button, ColumnLayout, Container, ContentLayout, ExpandableSection, Grid, Header, SpaceBetween, StatusIndicator, Table } from '@cloudscape-design/components';
import useInterval from '@use-it/interval';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import FictitiousAPI, { AssignmentObject, CourseObject, StudentObject, SubmissionObject } from '../api';
import { EssayResults } from '../components/EssayResults';
import { KeyValue } from '../components/KeyValue';
import { formatDate } from '../util/FormatUtil';

export const SubmissionDetails = () => {
    const { assignmentId, submissionId, courseId } = useParams();
    const navigate = useNavigate();

    const [submission, setSubmission] = useState<SubmissionObject | null>(null);
    const [assignment, setAssignment] = useState<AssignmentObject | null>(null);
    const [student, setStudent] = useState<StudentObject | null>(null);
    const [course, setCourse] = useState<CourseObject | null>(null);

    const fetchSubmission = async () => {
        console.log(submission);
        if (submission?.predictionState == 'PENDING' || submission?.predictionState == 'IN_PROGRESS') {
            console.log("Fetch submissions")
            const response = await FictitiousAPI.domain.getSubmissionUsingGet(assignmentId!, submissionId!);
            setSubmission(response.data.submissionObject);
            return response;
        }
    }

    const fetchData = async () => {
        const response = await FictitiousAPI.domain.getSubmissionUsingGet(assignmentId!, submissionId!);
        setSubmission(response.data.submissionObject);
        setAssignment(response.data.assignmentObject);
        const student = (await FictitiousAPI.domain.getCourseInfoUsingPost(response.data.assignmentObject.courseId)).data.students
            .filter(s => s.id == response.data.submissionObject.studentId)[0];
        setStudent(student!);
        const course = (await FictitiousAPI.domain.getCourseInfoUsingPost(courseId as string)).data;
        setCourse(course.courseObject);
    }

    useEffect(() => {
        fetchData();
    }, [assignmentId, submissionId, courseId]);

    useInterval(() => { fetchSubmission() }, 5000);

    let status;
    let aggregateProb = submission?.predictionResultObject?.result?.aggregateFake || 0;
    if (aggregateProb > 0.5) {
        aggregateProb = Math.min(1 - Math.random() / 10, aggregateProb + .15);
        status = 'error';
    } else {
        status = 'success';
    }

    return (<ContentLayout
        header={
            <SpaceBetween size="m">
                <BreadcrumbGroup
                    onClick={(e) => { e.preventDefault(); navigate(e.detail.href) }}
                    items={[
                        { text: "Home", href: "/" },
                        { text: "Courses", href: `/courses/${courseId}/assignments` },
                        {
                            text: ((course?.courseCode || "") + " " + course?.name) || "-",
                            href: "/courses/" + courseId
                        },
                        { text: "Assignments", href: `/courses/${courseId}/assignments` },
                        { text: assignment?.assignmentName, href: `/courses/${courseId}/assignments/${assignmentId}` },
                        { text: "Submissions", href: `/courses/${courseId}/assignments/${assignmentId}/submissions` },
                        { text: student?.name, href: `/courses/${courseId}/assignments/${assignmentId}/submissions/${submissionId}` }
                    ].filter(e => e !== undefined) as any}
                    ariaLabel="Breadcrumbs"
                />
                <div></div>
                <Header
                    variant="h1"
                // description="Click on a row to view the full report for a student's submission."
                >
                    Submissions from {student?.name} for {assignment?.assignmentName}
                </Header>
            </SpaceBetween>
        }
    >
        <SpaceBetween size="l">
            <Container>
                <ColumnLayout columns={3} variant='text-grid'>
                    <SpaceBetween size="l">
                        <KeyValue label="Student Name">
                            {student?.name}
                        </KeyValue>
                        <KeyValue label="Student Email">
                            {student?.email}
                        </KeyValue>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <KeyValue label="Assignment Name">
                            {assignment?.assignmentName}
                        </KeyValue>
                        <KeyValue label="Due Date">
                            {formatDate(assignment?.dueDate)}
                        </KeyValue>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <KeyValue label="Submission Time">
                            {formatDate(submission?.submissionTime)}
                        </KeyValue>
                        <KeyValue label="Submission Integrity">
                            {submission?.predictionState !== 'COMPLETE' ? '-' : <StatusIndicator type={status as any}>{(100 * aggregateProb).toFixed(2) + "% AI-generated"}</StatusIndicator>}
                        </KeyValue>
                    </SpaceBetween>
                </ColumnLayout>
            </Container>
            <SpaceBetween direction="vertical" size="s">
                <Alert
                    header="Understanding your results"
                >
                    <ExpandableSection variant="footer" headerText="How do I read your visualizations? What do the colors mean?">
                        We visualize results with four colors: green, yellow, orange, and red. These represent increasing likelihoods that the sentences or paragraphs they belong to were AI-generated.
                        Our platform also outputs a "level of confidence" for each prediction, which corresponds to the transparency of the color. The higher the level of confidence, the stronger the color.
                        {/* You can also click on a sentence or paragraph to see the level of confidence and probability associated with the prediction. */}
                    </ExpandableSection>
                    <ExpandableSection variant="footer" headerText="Why were my paragraph breaks modified?">
                        In some cases, our model may rearrange paragraph breaks in your submission. This is done to help improve the accuracy of our predictions when your input data may have had abnormally short
                        or long paragraphs.
                    </ExpandableSection>
                </Alert>
                {submission && <EssayResults submission={submission} />}
            </SpaceBetween>
        </SpaceBetween >
    </ContentLayout >)
}