import { Alert, Button, Container, Form, FormField, Header, Input, SpaceBetween, Spinner } from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import { redirect, useParams } from "react-router-dom";

import FictitiousAPI from "../api";

export const AdminProvisionUser = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [loading, setLoading] = useState<boolean>(false);
    const [response, setResponse] = useState<string | null>(null);

    useEffect(() => {
        FictitiousAPI.accounts.getUserUsingGet().then(response => {
            if (!response.data.administrator) {
                redirect("/");
            }
        });
    }, []);

    const createAccount = async () => {
        setLoading(true);
        setResponse(null);
        const response = await FictitiousAPI.admin.provisionDemoUserUsingPost({ firstName, lastName });
        setResponse(response.data.demoLink!);
        setLoading(false);
    }

    return (
        <Container>
            {loading && <Spinner size="large" />}
            {response && <Alert type="info" action={
                <Button iconName="copy" variant="inline-icon" onClick={() => { navigator.clipboard.writeText(response) }} />
            }>Demo Link: {response}</Alert>}
            <form onSubmit={e => e.preventDefault()}>
                <Form
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="primary" disabled={loading} loading={loading} onClick={() => createAccount()}>Submit</Button>
                        </SpaceBetween>
                    }
                    header={<Header variant="h1">Admin Provision User</Header>}
                >
                    <FormField label="First Name" description="firstName"><Input disabled={loading} value={firstName} onChange={(e) => setFirstName(e.detail.value)} /></FormField>
                    <FormField label="Last Name" description="lastName"><Input disabled={loading} value={lastName} onChange={(e) => setLastName(e.detail.value)} /></FormField>
                </Form>
            </form>
        </Container>
    );
}